// import email from "../assets/email.png";
// import mobile from "../assets/mobile.png";
// import api from "../assets/api.png";
// import cpp from "../assets/cpp.png";
// import css from "../assets/css.png";
// import figma from "../assets/figma.png";
// import flutter from "../assets/flutter.png";
// import git from "../assets/git.png";
// import graphql from "../assets/graphql.png";
// import html from "../assets/html.png";
// import javascript from "../assets/javascript.png";
// import mu5 from "../assets/mu5.png";
// import node from "../assets/node.png";
// import python from "../assets/python.png";
// import react from "../assets/react.png";
// import redux from "../assets/redux.png";
// import sass from "../assets/sass.png";
// import typescript from "../assets/typescript.png";
// import vue from "../assets/vue.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/the-halal-design-studio-_j3o_uUCeis-unsplash.jpg";
import about04 from "../assets/about04.png";
import about05 from "../assets/milad-fakurian-PGdW_bHDbpI-unsplash.jpg";

// import profile from "../assets/profile3.png";
import profile from "../assets/home1.JPG";
import circle from "../assets/circle.svg";
import logo from "../assets/aish-logo.png";

// import adidas from "../assets/adidas.png";
// import amazon from "../assets/amazon.png";
// import asus from "../assets/asus.png";
// import bolt from "../assets/bolt.png";
// import nb from "../assets/nb.png";
// import skype from "../assets/skype.png";
// import spotify from "../assets/spotify.png";

// import tool1 from "../assets/tools1.png";
import tool2 from "../assets/tools2.png";
import tool3 from "../assets/tools3.png";

import chameleon from "../assets/3d-stylized/chamaleon_work1.png";
import sloopy from "../assets/3d-stylized/sloopy_work2.png";
import childhood from "../assets/3d-stylized/childhood_work3.png";
import fairysHouse from "../assets/3d-stylized/fairys-house_work4.png";
import chest from "../assets/3d-stylized/chest_work5.png";
import tribalman from "../assets/3d-stylized/Tribal-man.jpg";
import lantern from "../assets/3d-stylized/Anicent_Lantern.jpg";

import bear from "../assets/2d-images/bear.JPG";
import benny from "../assets/2d-images/benny.JPG";
import bunny from "../assets/2d-images/bunny.JPG";
import child2 from "../assets/2d-images/child2.JPG";
import child3 from "../assets/2d-images/child3.JPG";
import childpotrait from "../assets/2d-images/childpotrait.JPG";
import dino from "../assets/2d-images/dino.JPG";
import mojojojo from "../assets/2d-images/mojojojo.JPG";
import motherpotrait from "../assets/2d-images/motherpotrait.JPG";
import oldCouple from "../assets/2d-images/oldCouple.JPG";
import ruby from "../assets/2d-images/ruby.JPG";
import turtle from "../assets/2d-images/turtle.JPG";

import model01 from "../assets/3d-reality/Model01.jpg";
import model02 from "../assets/3d-reality/Model02.jpg";
import model03 from "../assets/3d-reality/Model03.jpg";
import model04 from "../assets/3d-reality/Model04.jpg";
import model05 from "../assets/3d-reality/Model05.jpg";
import model06 from "../assets/3d-reality/Model06.jpg";
import model07 from "../assets/3d-reality/Model07.jpg";
import model08 from "../assets/3d-reality/Model08.jpg";
import model09 from "../assets/3d-reality/Model09.jpg";
import model10 from "../assets/3d-reality/Model10.jpg";
import model11 from "../assets/3d-reality/Model11.jpg";

import model01_1 from "../assets/3d-reality/subimages/laundary/laundary1.jpg";
import model01_2 from "../assets/3d-reality/subimages/laundary/laundary2.jpg";
import model01_3 from "../assets/3d-reality/subimages/laundary/laundary3.jpg";
import model01_4 from "../assets/3d-reality/subimages/laundary/laundary4.jpg";
import model01_5 from "../assets/3d-reality/subimages/laundary/laundary5.jpg";

import model02_1 from "../assets/3d-reality/subimages/tablelamp-02/tl02_1.jpg";
import model02_2 from "../assets/3d-reality/subimages/tablelamp-02/tl02_2.jpg";
import model02_3 from "../assets/3d-reality/subimages/tablelamp-02/tl02_3.jpg";

import model03_1 from "../assets/3d-reality/subimages/tablelamp-03/tl03-01.jpg";

import model04_1 from "../assets/3d-reality/subimages/trolly-stand/ts1.jpg";
import model04_2 from "../assets/3d-reality/subimages/trolly-stand/ts2.jpg";
import model04_3 from "../assets/3d-reality/subimages/trolly-stand/ts3.jpg";
import model04_4 from "../assets/3d-reality/subimages/trolly-stand/ts4.jpg";
import model04_5 from "../assets/3d-reality/subimages/trolly-stand/ts5.jpg";
import model04_6 from "../assets/3d-reality/subimages/trolly-stand/ts6.jpg";

import model05_1 from "../assets/3d-reality/subimages/tablelamp-01/tl-01.jpg";
import model05_2 from "../assets/3d-reality/subimages/tablelamp-01/tl-02.jpg";
import model05_3 from "../assets/3d-reality/subimages/tablelamp-01/tl-03.jpg";
import model05_4 from "../assets/3d-reality/subimages/tablelamp-01/tl-04.jpg";

import model06_1 from "../assets/3d-reality/subimages/tufted-chair/tfchair-01.jpg";
import model06_2 from "../assets/3d-reality/subimages/tufted-chair/tfchair-02.jpg";
import model06_3 from "../assets/3d-reality/subimages/tufted-chair/tfchair-03.jpg";
import model06_4 from "../assets/3d-reality/subimages/tufted-chair/tfchair-04.jpg";
import model06_5 from "../assets/3d-reality/subimages/tufted-chair/tfchair-05.jpg";
import model06_6 from "../assets/3d-reality/subimages/tufted-chair/tfchair-06.jpg";

import model07_1 from "../assets/3d-reality/subimages/vase/vase-01.jpg";
import model07_2 from "../assets/3d-reality/subimages/vase/vase-02.jpg";
import model07_3 from "../assets/3d-reality/subimages/vase/vase-03.jpg";
import model07_4 from "../assets/3d-reality/subimages/vase/vase-04.jpg";
import model07_5 from "../assets/3d-reality/subimages/vase/vase-05.jpg";
import model07_6 from "../assets/3d-reality/subimages/vase/vase-06.jpg";

import model08_1 from "../assets/3d-reality/subimages/office-chair/chair-01.jpg";
import model08_2 from "../assets/3d-reality/subimages/office-chair/chair-02.jpg";
import model08_3 from "../assets/3d-reality/subimages/office-chair/chair-03.jpg";
import model08_4 from "../assets/3d-reality/subimages/office-chair/chair-04.jpg";
import model08_5 from "../assets/3d-reality/subimages/office-chair/chair-05.jpg";
import model08_6 from "../assets/3d-reality/subimages/office-chair/chair-06.jpg";

import model09_1 from "../assets/3d-reality/subimages/soft-toy/st-1.jpg";
import model09_2 from "../assets/3d-reality/subimages/soft-toy/st-2.jpg";
import model09_3 from "../assets/3d-reality/subimages/soft-toy/st-3.jpg";
import model09_4 from "../assets/3d-reality/subimages/soft-toy/st-4.jpg";
import model09_5 from "../assets/3d-reality/subimages/soft-toy/st-5.jpg";
import model09_6 from "../assets/3d-reality/subimages/soft-toy/st-6.jpg";

import model10_1 from "../assets/3d-reality/subimages/wall-clock/wc-01.jpg";
import model10_2 from "../assets/3d-reality/subimages/wall-clock/wc-02.jpg";
import model10_3 from "../assets/3d-reality/subimages/wall-clock/wc-03.jpg";
import model10_4 from "../assets/3d-reality/subimages/wall-clock/wc-04.jpg";
import model10_5 from "../assets/3d-reality/subimages/wall-clock/wc-05.jpg";
import model10_6 from "../assets/3d-reality/subimages/wall-clock/wc-06.jpg";

import model11_1 from "../assets/3d-reality/subimages/suitcase/sc-01.jpg";
import model11_2 from "../assets/3d-reality/subimages/suitcase/sc-02.jpg";
import model11_3 from "../assets/3d-reality/subimages/suitcase/sc-03.jpg";
import model11_4 from "../assets/3d-reality/subimages/suitcase/sc-04.jpg";
import model11_5 from "../assets/3d-reality/subimages/suitcase/sc-05.jpg";
import model11_6 from "../assets/3d-reality/subimages/suitcase/sc-06.jpg";

import tool01 from "../assets/tools/t1.PNG";
import tool02 from "../assets/tools/t2.PNG";
import tool03 from "../assets/tools/t3.PNG";
import tool04 from "../assets/tools/t4.PNG";
import tool05 from "../assets/tools/t5.PNG";
import tool06 from "../assets/tools/t6.PNG";

export default {
	tool01,
	tool02,
	tool03,
	tool04,
	tool05,
	tool06,
	model11_1,
	model11_2,
	model11_3,
	model11_4,
	model11_5,
	model11_6,
	model10_1,
	model10_2,
	model10_3,
	model10_4,
	model10_5,
	model10_6,
	model09_1,
	model09_2,
	model09_3,
	model09_4,
	model09_5,
	model09_6,
	model08_1,
	model08_2,
	model08_3,
	model08_4,
	model08_5,
	model08_6,
	model07_1,
	model07_2,
	model07_3,
	model07_4,
	model07_5,
	model07_6,
	model06_1,
	model06_2,
	model06_3,
	model06_4,
	model06_5,
	model06_6,
	model05_1,
	model05_2,
	model05_3,
	model05_4,
	model04_1,
	model04_2,
	model04_3,
	model04_4,
	model04_5,
	model04_6,
	model03_1,
	model02_1,
	model02_2,
	model02_3,
	model01_1,
	model01_2,
	model01_3,
	model01_4,
	model01_5,
	model01,
	model02,
	model03,
	model04,
	model05,
	model06,
	model07,
	model08,
	model09,
	model10,
	model11,
	turtle,
	ruby,
	oldCouple,
	motherpotrait,
	mojojojo,
	dino,
	childpotrait,
	child3,
	child2,
	bunny,
	benny,
	bear,
	chest,
	tribalman,
	lantern,
	fairysHouse,
	childhood,
	sloopy,
	chameleon,
	// email,
	// mobile,
	// api,
	// cpp,
	// css,
	// figma,
	// flutter,
	// git,
	// graphql,
	// html,
	// javascript,
	// mu5,
	// node,
	// python,
	// react,
	// redux,
	// sass,
	// typescript,
	// vue,
	about01,
	about02,
	about03,
	about04,
	about05,
	profile,
	circle,
	logo,
	// adidas,
	// amazon,
	// asus,
	// bolt,
	// nb,
	// skype,
	// spotify,
	// tool1,
	tool2,
	tool3,
};
