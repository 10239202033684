import { images } from "../../constants";

export const skillsData = [
  {
    imgURL: images.tool01,
    heading: "Blender",
    rating: "100%",
    description: ["3D Modeling", "UV unwrapping", "Texturing", "Basic rigging", "Basic Animation", "Lighting", "Rendering"],
  },
  {
    imgURL: images.tool02,
    heading: "Z-Brush",
    rating: "100%",
    description: [
      "Realistic and Stylized Character sculpting",
      "Realistic and Stylized Assets sculpting",
      "3D Environment sculpting",
      "Concept art creation",
      "Realistic product sculpting",
      "3D Landmark sculpting",
    ],
  },
  {
    imgURL: images.tool03,
    heading: "Maya",
    rating: "100%",
    description: ["3D Modeling", "UV unwrapping", "Texturing", "Basic rigging", "Basic Animation", "Lighting", "Rendering"],
  },
  {
    imgURL: images.tool04,
    heading: "Substance Painter",
    rating: "100%",
    description: ["Baking Maps", "Realistic texturing", "Stylized texturing", "Creating smart materials", "Creating Alpha and Procedural maps"],
  },
  {
    imgURL: images.tool05,
    heading: "Photoshop",
    rating: "100%",
    description: [
      "Photo editing",
      "Vector tracing",
      "Free hand drawing",
      "Color theory ",
      "Print design: flyers, posters, brochures, business cards.",
      "Sketching",
    ],
  },
];
