import { images } from "../../constants";
export const categories = ["All", "3D Stylized", "3D Reality", "2D Art"];
export const workData = [
	{
		title: "Tribal Man",
		description:
			"This 3D model of a tribal man is a beautiful and realistic representation of a man from a traditional tribal culture. The man is wearing a loincloth and carrying a ratchet knife. The 3D model is very detailed, and it captures the man's physical features and clothing with great accuracy. ",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink: "",
		codeLink:
			"https://sketchfab.com/models/d3b99f06d23b40dea3253720fe6a5b9b/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.tribalman,
		tags: ["3D Stylized", "All"],
		subImages: [images.tribalman],
	},
	{
		title: "Ancient Lantern",
		description:
			"This 3D model of an ancient lantern is a beautiful and intricate work of art. It is made of stone, and it is decorated with intricate carvings and patterns. The lantern has a glass and oil-burning lamp inside, which provides a soft light.",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink: "",
		codeLink:
			"https://sketchfab.com/models/cd388150534d478fb816ea242dfb3c68/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.lantern,
		tags: ["3D Stylized", "All"],
		subImages: [images.childhood],
	},
	{
		title: "Chamaleon Having Coffee",
		description:
			"Here’s a Chameleon having coffee after a very long stressful day, click on the image to view the 3D model and enjoy.",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink:
			"https://aishwaryapearala.blogspot.com/2022/08/chamaleon-having-coffee.html",
		codeLink:
			"https://sketchfab.com/models/e9bd68378b5f475a8c2cc17c18c40a03/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.chameleon,
		tags: ["3D Stylized", "All"],
		subImages: [images.chameleon],
	},
	{
		title: "Sloopy The Snail",
		description:
			"Meet Sloopy, a confused little snail, who is probably very confused and scared to cross the water. Click on the image to view the 3D model and enjoy.",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink: "",
		codeLink:
			"https://sketchfab.com/models/765727579c7947cbaa5c52c9211850d8/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.sloopy,
		tags: ["3D Stylized", "All"],
		subImages: [images.sloopy],
	},

	{
		title: "Chest Concept",
		description:
			"This 3D model of a chest is a beautiful and stylized representation of a gameing chest. The chest is made of wood and has a metal lock. The chest is decorated with intricate carvings and patterns. The 3D model is very detailed and captures the chest's physical features with great accuracy.",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink: "",
		codeLink:
			"https://sketchfab.com/models/0b24d89844c14509afbbf08c90d184d4/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.chest,
		tags: ["3D Stylized", "All"],
		subImages: [images.chest],
	},
	{
		title: "Childhood Adventure",
		description:
			"We all have our imaginary adventure as kids, this is my imagination of a little boy who is on an adventure of his own. To know more of his adventure click on the image to view the 3D model and enjoy. ",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink: "",
		codeLink:
			"https://sketchfab.com/models/0284b1b8e4f044de94021a36aa74054e/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.childhood,
		tags: ["3D Stylized", "All"],
		subImages: [images.childhood],
	},
	{
		title: "Fairy's House",
		description:
			"This 3D model of a fairy's house is a beautiful and whimsical representation of a fairy's home. The house is made of wood. The house is decorated with flowers.The 3D model is very detailed and captures the house's physical features with great accuracy.",
		description2:
			"Software’s Used: Autodesk Maya, Z-Brush, Substance painter, Photoshop.",
		projectLink: "",
		codeLink:
			"https://sketchfab.com/models/8734490ed21e47aca057c5eaec4f2b71/embed?autospin=1&autostart=1&preload=1",
		imgURL: images.fairysHouse,
		tags: ["3D Stylized", "All"],
		subImages: [images.fairysHouse],
	},
	{
		title: "Laundry Basket",
		description:
			"Realistic Laundry basket made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model01,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model01_1,
			images.model01_2,
			images.model01_3,
			images.model01_4,
			images.model01_5,
		],
	},
	{
		title: "Table Lamp",
		description:
			"Realistic Table Lamp made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model02,
		tags: ["3D Reality", "All"],
		subImages: [images.model02_1],
	},
	{
		title: "Antique Table Lamp",
		description:
			"Realistic Antique Table Lamp made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model03,
		tags: ["3D Reality", "All"],
		subImages: [images.model03_1],
	},
	{
		title: "Trolly Stand",
		description:
			"Realistic trolly stand made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model04,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model04_1,
			images.model04_2,
			images.model04_3,
			images.model04_4,
			images.model04_5,
			images.model04_6,
		],
	},
	{
		title: "Modern Table Lamp",
		description:
			"Realistic modern table lamp made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model05,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model05_1,
			images.model05_2,
			images.model05_3,
			images.model05_4,
		],
	},
	{
		title: "Tufted Chair",
		description:
			"Realistic Tufted chair made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model06,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model06_1,
			images.model06_2,
			images.model06_3,
			images.model06_4,
			images.model06_5,
			images.model06_6,
		],
	},
	{
		title: "Authentic Chinese Vase",
		description:
			"Realistic Authentic Chinese vase made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model07,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model07_1,
			images.model07_2,
			images.model07_3,
			images.model07_4,
			images.model07_5,
			images.model07_6,
		],
	},
	{
		title: "Gaming Chair",
		description:
			"Realistic gaming chair made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Z-brush, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model08,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model08_1,
			images.model08_2,
			images.model08_3,
			images.model08_4,
			images.model08_5,
			images.model08_6,
		],
	},
	{
		title: "Soft Toy",
		description:
			"Realistic Soft toy made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Z-brush, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model09,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model09_1,
			images.model09_2,
			images.model09_3,
			images.model09_4,
			images.model09_5,
			images.model09_6,
		],
	},
	{
		title: "Wall Clock",
		description:
			"Realistic Wall Clock made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Z-brush, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model10,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model10_1,
			images.model10_2,
			images.model10_3,
			images.model10_4,
			images.model10_5,
			images.model10_6,
		],
	},
	{
		title: "Suitcase Drawer",
		description:
			"Realistic Suitcase Drawer made in 3D for Amazon AR and VR experience project.",
		description2:
			"Software’s Used: Autodesk Maya, Z-brush, Substance painter, Photoshop.",
		projectLink: null,
		codeLink: null,
		imgURL: images.model11,
		tags: ["3D Reality", "All"],
		subImages: [
			images.model11_1,
			images.model11_2,
			images.model11_3,
			images.model11_4,
			images.model11_5,
			images.model11_6,
		],
	},

	{
		title: "Digital Painting baby portrait",
		description:
			"One of my best attempts to create baby portrait digitally which turned out so cute.",
		description2: "Software Used: Photoshop",
		projectLink: null,
		codeLink: null,
		imgURL: images.childpotrait,
		tags: ["2D Art", "All"],
		subImages: [images.childpotrait],
	},
	// {
	//   title: "Bear King",
	//   description: "Getting old school with a pencil and paper sketches. Please do check him by clicking on the image. ",
	//   description2: "(Graphite pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.bear,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.bear],
	// },
	{
		title: "Beauty",
		description:
			"My growing interest on color pencils which makes my sketches look more beautiful.",
		description2: "(Colored pencil sketch)",
		projectLink: null,
		codeLink: null,
		imgURL: images.benny,
		tags: ["2D Art", "All"],
		subImages: [images.benny],
	},
	// {
	//   title: "Bunny in Rain",
	//   description: "This bunny is enjoying his time in rain as much as I enjoyed my time in making this sketch.",
	//   description2: "(Colored pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.bunny,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.bunny],
	// },
	// {
	//   title: "Baby portrait",
	//   description: "Check out this realistic portrait, which I made for my colleagues’ daughter.",
	//   description2: "(Graphite pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.child2,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.child2],
	// },
	// {
	//   title: "Baby portrait - Colored",
	//   description: "This is my 1st attempt to create a baby portrait with colored pencils.",
	//   description2: "(Colored pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.child3,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.child3],
	// },

	// {
	//   title: "Dino",
	//   description: "Cute little dino having fun.",
	//   description2: "(Colored pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.dino,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.dino],
	// },
	// {
	//   title: "Mojo-Jojo",
	//   description: "One of my favorite cartoon characters as a kid growing up is 'Mojo-JoJo', really enjoyed sketching this one.",
	//   description2: "(Colored pencil sketch)",
	//   projectLink:null,
	//   codeLink: null,
	//   imgURL: images.mojojojo,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.mojojojo],
	// },
	// {
	//   title: "Mother-Daughter Potrait",
	//   description: "Realistic portrait of a mother and daughter, this one was quite challenging to make but was quite fun.",
	//   description2: "(Graphite pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.motherpotrait,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.motherpotrait],
	// },
	// {
	//   title: "Couple Portrait",
	//   description: "Realistic portrait of a couple.",
	//   description2: "(Graphite pencil sketch)",
	//   projectLink:null,
	//   codeLink:null,
	//   imgURL: images.oldCouple,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.oldCouple],
	// },
	// {
	//   title: "Ruby",
	//   description: "Inspired from watching cartoons, here is one color pencil sketch.",
	//   description2: "(Colored pencil sketch)",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.ruby,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.ruby],
	// },
	// {
	//   title: "Turtle",
	//   description: "Turtle color pencil sketch.",
	//   description2: "",
	//   projectLink: null,
	//   codeLink: null,
	//   imgURL: images.turtle,
	//   tags: ["2D Art", "All"],
	//   subImages: [images.turtle],
	// },
];
